define("discourse/plugins/hosted-site/discourse/components/modal/admin-invoices", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @title="Invoices" @closeModal={{@closeModal}} class="hosted-invoices">
    <:body>
      {{#if @model.invoices.length}}
        <table class="hosted-invoices-table">
          <thead>
            <tr>
              <th>Number</th>
              <th>Product</th>
              <th>Addon</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {{#each @model.invoices as |invoice|}}
              <tr>
                <td>{{invoice.number}}</td>
                <td>{{invoice.product_name}}</td>
                <td>{{invoice.addon_name}}</td>
                <td>${{invoice.total}}</td>
                <td>{{invoice.status}}</td>
                <td>{{invoice.date}}</td>
                <td>
                  <DButton
                    @href={{invoice.hosted_url}}
                    @icon="file-invoice"
                    @label="hosted_site.view_invoice"
                    class="btn-default"
                  />
                </td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      {{else}}
        <p>No available invoices.</p>
      {{/if}}
    </:body>
  </DModal>
  */
  {
    "id": "+Mcg3md2",
    "block": "[[[8,[39,0],[[24,0,\"hosted-invoices\"]],[[\"@title\",\"@closeModal\"],[\"Invoices\",[30,1]]],[[\"body\"],[[[[1,\"\\n\"],[41,[30,2,[\"invoices\",\"length\"]],[[[1,\"      \"],[10,\"table\"],[14,0,\"hosted-invoices-table\"],[12],[1,\"\\n        \"],[10,\"thead\"],[12],[1,\"\\n          \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Number\"],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Product\"],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Addon\"],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Amount\"],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Status\"],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Date\"],[13],[1,\"\\n            \"],[10,\"th\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,2,[\"invoices\"]]],null]],null],null,[[[1,\"            \"],[10,\"tr\"],[12],[1,\"\\n              \"],[10,\"td\"],[12],[1,[30,3,[\"number\"]]],[13],[1,\"\\n              \"],[10,\"td\"],[12],[1,[30,3,[\"product_name\"]]],[13],[1,\"\\n              \"],[10,\"td\"],[12],[1,[30,3,[\"addon_name\"]]],[13],[1,\"\\n              \"],[10,\"td\"],[12],[1,\"$\"],[1,[30,3,[\"total\"]]],[13],[1,\"\\n              \"],[10,\"td\"],[12],[1,[30,3,[\"status\"]]],[13],[1,\"\\n              \"],[10,\"td\"],[12],[1,[30,3,[\"date\"]]],[13],[1,\"\\n              \"],[10,\"td\"],[12],[1,\"\\n                \"],[8,[39,4],[[24,0,\"btn-default\"]],[[\"@href\",\"@icon\",\"@label\"],[[30,3,[\"hosted_url\"]],\"file-invoice\",\"hosted_site.view_invoice\"]],null],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,2],[12],[1,\"No available invoices.\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]]],[\"@closeModal\",\"@model\",\"invoice\"],false,[\"d-modal\",\"if\",\"each\",\"-track-array\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-invoices.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "admin-invoices"));
});