define("discourse/plugins/hosted-site/discourse/connectors/admin-plugin-list-name-badge-after/plugin-plan-badge", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PluginPlanBadge extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "upgrade", [_service.inject]))();
    #upgrade = (() => (dt7948.i(this, "upgrade"), void 0))();
    get showBadge() {
      return this.args.outletArgs.plugin.planNeeded && !this.args.outletArgs.plugin.isIncludedInPlan;
    }
    async showUpgradeModal() {
      await this.upgrade.modalForPlan(this.args.outletArgs.plugin.planNeeded, `/admin/plugins ${this.args.outletArgs.plugin.id} plan badge`);
    }
    static #_2 = (() => dt7948.n(this.prototype, "showUpgradeModal", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showBadge}}
          <DButton
            @action={{this.showUpgradeModal}}
            @translatedLabel="🔓 Unlock this plugin"
            class="plugin-plan-needed-badge"
          />
        {{/if}}
        {{yield}}
      
    */
    {
      "id": "18rLRHcP",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showBadge\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"plugin-plan-needed-badge\"]],[[\"@action\",\"@translatedLabel\"],[[30,0,[\"showUpgradeModal\"]],\"🔓 Unlock this plugin\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,1,null],[1,\"\\n  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/hosted-site/discourse/connectors/admin-plugin-list-name-badge-after/plugin-plan-badge.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PluginPlanBadge;
});