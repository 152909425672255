define("discourse/plugins/hosted-site/discourse/components/free-not-supported", ["exports", "@ember/component", "@ember-decorators/component", "discourse/lib/text", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _component2, _text, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FreeNotSupported = dt7948.c(class FreeNotSupported extends _component.default {
    noEntryEmojiURL = (() => (0, _text.emojiUrlFor)("no_entry"))();
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <img src={{this.noEntryEmojiURL}} alt="" />
        <div class="content-wrapper">
          <h1>{{i18n "hosted_site.customize.free_not_supported.title"}}</h1>
          <p class="description">{{i18n
              "hosted_site.customize.free_not_supported.description"
            }}</p>
        </div>
      
    */
    {
      "id": "cnkYsiFm",
      "block": "[[[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"noEntryEmojiURL\"]]],[14,\"alt\",\"\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"content-wrapper\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,[28,[32,0],[\"hosted_site.customize.free_not_supported.title\"],null]],[13],[1,\"\\n      \"],[10,2],[14,0,\"description\"],[12],[1,[28,[32,0],[\"hosted_site.customize.free_not_supported.description\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/hosted-site/discourse/components/free-not-supported.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }, [(0, _component2.classNames)("themes-intro", "admin-intro", "free-tier-themes-intro")]);
  var _default = _exports.default = FreeNotSupported;
});