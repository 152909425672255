define("discourse/plugins/hosted-site/discourse/templates/connectors/before-backup-list/hosted-site", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="alert alert-info">
    {{html-safe (i18n "hosted_site.backup_note")}}
  </div>
  */
  {
    "id": "Ez/Gljnm",
    "block": "[[[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[28,[37,1],[\"hosted_site.backup_note\"],null]],null]],[1,\"\\n\"],[13]],[],false,[\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/templates/connectors/before-backup-list/hosted-site.hbs",
    "isStrictMode": false
  });
});